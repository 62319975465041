p{
    font-size: 16px;
    line-height: 32px;
}
span{
    font-size: 16px;
}
.match-list-container img{
    height: 156px;
    width: 200px;
    object-fit: contain;
}
span[data-testid="credit-source"] {
    display: none;
    }
    .img-box{
        display: none
    }
    .custom-add-comment{
        display: none
    }
    .premium-story{
        display: none;
    }
    .heading{
        font-size: 22px!important;
        color: #103c8c;
        line-height: 28px;
        font-weight: 500;
        /* margin-bottom: 20px; */
    }
    .article-description{
        font-weight: normal;
font-size: 22px;
line-height: 32px;


color: #111111;
    }
    ul li{
        line-height: 32px;
        font-size: 16px;
    }