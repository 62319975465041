body {
  font-family: "Poppins", sans-serif !important;
}
* {
  font-family: "Poppins", sans-serif !important;
}
.cursor-pointer {
  cursor: pointer;
}
.w-full {
  width: 100%;
}
.bg-white {
  background-color: white;
}
.bg-black {
  background-color: #111111;
}

.color-yellow {
  color: #f8bb22;
}

.color-white {
  color: white;
}

.ml-50 {
  margin-left: 50px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-5 {
  margin-top: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.pd-10 {
  padding: 10px;
}
/* container */
.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  background: #eef0f6;
  padding-left: 15px;
  /* height: 93vh; */
  padding: 20px;
}
@media (max-width: 476px) {
  .container {
    height: unset;
  }
}
@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1340px;
    max-width: 100%;
  }
}
/* end container */

/* header */
.ant-menu-item-selected::after {
  border-bottom-color: #f8bb22 !important;
  bottom: 14px !important;
}
.ant-menu-item-selected {
  color: #f8bb22 !important;
}
.ant-menu-light
  .ant-menu-item:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ) {
  color: #f8bb22 !important;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
  border-bottom-color: #f8bb22 !important;
  bottom: 14px !important;
}
.ant-menu-root span {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}
/* end header */

/* home */
.tag {
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #a6adb4;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #ffffff,
    6px 6px 36px rgba(0, 0, 0, 0.06);
  border-radius: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3a4859;
  cursor: pointer;
}
.active-tag {
  padding: 15px;
  background: #1a1a1a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #f8bb22;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #ffffff,
    6px 6px 36px rgba(0, 0, 0, 0.06);
  border-radius: 40px;
  cursor: pointer;
}
.news-container {
  background: #eef0f6;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #ffffff,
    6px 6px 36px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 30px;
  overflow-y: scroll;
  height: 82vh;
}
.detail-news-container {
  background: #eef0f6;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #ffffff,
    6px 6px 36px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 18px;
  overflow-y: scroll;
  height: 82vh;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 4px solid #d9d9d9;
}
.ant-tabs .ant-tabs-ink-bar {
  background: #c6961b;
  height: 5px !important;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1a1a1a !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.ant-tabs .ant-tabs-tab-btn {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #515151 !important;
}
.top-news-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 10px;
  color: #000000;
}
.news-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 100px;
}
.news-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
}
.devider {
  border: 1px solid #d9d9d9;
}
.match-list-container {
  padding: 10px;
}

.match-card {
  background: #eef0f6;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #ffffff,
    6px 6px 36px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
  /* width: 382px; */
  /* height: 200px; */
  position: relative;
  margin-bottom: 10px;
  /* width: 80%; */
}

.match-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  letter-spacing: 0.5px;

  color: #707585;
}
.space-between {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.live-dot {
  width: 6px;
  height: 6px;
  border-radius: 100px;

  background: #ef233c;
}
.live {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  text-align: center;
  letter-spacing: 0.5px;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.71);
}
.space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}
.text-center {
  text-align: center;
}
.team {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  text-align: center;
  letter-spacing: 1px;

  color: #000000;
}
.vs {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #f8bb22;
}
.vs-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  text-align: center;
  letter-spacing: 1px;

  color: #ffffff;
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
}
.score {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  text-align: center;
  letter-spacing: 0.5px;

  color: rgba(0, 0, 0, 0.9);
}

.score1 {
  font-family: "Poppins";
  font-style: normal;
  width: 127%;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;

  text-align: center;
  letter-spacing: 0.5px;

  color: rgba(0, 0, 0, 0.9);
  /* margin-bottom: px; */
}
.over {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  text-align: center;
  letter-spacing: 0.5px;

  color: rgba(112, 117, 133, 0.75);
}
@media (max-width: 476px) {
  .score,
  .over {
    font-size: 12px;
  }
}
/* end home */

/* details */
.ant-breadcrumb li:last-child {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 22px !important;

  color: #1a1a1a !important;
}
.ant-breadcrumb li span {
  /* font-weight: 400; */
  font-size: 15px;
  line-height: 22px;

  text-align: center;
}
.back-button {
  width: 40px;
  height: 40px;
  background: #eef0f6;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #ffffff,
    6px 6px 36px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100%;
}
.match-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;

  color: #707585;
}
.score-provier {
  background: #eef0f6;
  padding: 20px;

  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #ffffff,
    6px 6px 36px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
}
.title-score-provider {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #808080;
}
.flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.f-12 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.5px;

  color: rgba(112, 117, 133, 0.75);
}
.ball-left {
  width: 146px;
  height: 22px;
  left: 602px;
  top: 254px;

  background: #f8bb22;
  border-radius: 5px;

  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  text-align: center;
  letter-spacing: 1px;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.table-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;

  letter-spacing: 0.5px;

  color: #9096a5;
}
.justify-center {
  justify-content: center;
}
.ant-table-content {
  overflow: scroll;
}
.news-single-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  /* Black */

  color: #14191f;
}
.mb-10 {
  margin-bottom: 10px;
}
/* end details */

/* news */

.news-poster {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  letter-spacing: 1px;
  /* text-decoration-line: underline; */

  color: rgba(0, 0, 0, 0.8);
}
.news-poster-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 1px;

  color: rgba(0, 0, 0, 0.8);
}
.description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1px;

  color: #868686;
}
.time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 1px;

  color: #000000;
}
/* end news */

.home-news {
  height: 200px;
  width: 100%;
  object-fit: contain;
}
.news-image-container {
  height: 156px;
  width: 200px;

  /* object-fit: contain; */
}
section[data-testid="editors-picks"] {
  display: none;
}

.social-icon {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.center-vertical {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 70vh; */
}
.menu-followus {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #111111;
  margin-bottom: 20px;
}
.menu-title {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */

  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #111111;
}
/* .slick-slide {
  margin: 0 px; }

 .slick-list {
  margin: 0 5px; } */

.slick-slide div:nth-of-type(1) {
  padding: 10px;
}
.slider-new-single div:nth-of-type(1) {
  padding-left: 5px !important;
  padding-right: 5px !important;
  /* padding-top: 5px!important; */
  padding-bottom: 0px !important;
}

@media only screen and (max-width: 768px) {
  .slider-new-single div:nth-of-type(1) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    /* padding-top: 5px!important; */
    padding-bottom: 0px !important;
  }
  .slick-prev:before,
  .slick-next:before {
    color: black !important;
  }
  .slick-prev {
    left: -13px !important;
    z-index: 999999999999 !important;
  }
  .slick-next {
    right: -13px !important;
    z-index: 999999999999 !important;
  }
}
.center-block {
  display: block;
  justify-content: center;
  align-items: center;
}

.slick-slider {
  margin-top: -21px;
}

.main-news-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin-top: 10px;
  border-radius: 12px;
}
.main-news-title {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;

  color: #111111;
}

.detail-news-container1 {
  background: #eef0f6;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #ffffff,
    6px 6px 36px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 18px;
  height: 100%;
}

.one-ellips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.two-ellips {
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 97px;
}
.two-ellips1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* height: 70px; */
}
.small-news-card-image {
  width: 100%;
  object-fit: cover;
  height: 200px;
}
.small-news-card-image-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: #000000;
}
.top-news .slick-dots {
  bottom: -13px !important;
}
@media only screen and (max-width: 768px) {
  .main-news-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    margin-top: 5px;
    color: #111111;
  }
  .main-news-image {
    height: 200px;
    margin-top: 10px;
  }
  .top-news .slick-dots {
    bottom: -3px !important;
  }
}
footer {
  background-color: black !important;
  /* height: 80px; */
  display: flex;
  align-items: center;
}
.home-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  width: 100%;
  text-align: center;

  color: #707585;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .home-heading {
    font-size: 18px;
  }
  .ant-layout-header {
    padding-inline: 30px;
  }
}
.start-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  letter-spacing: 0.5px;

  color: #707585;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ant-menu-item::after {
  bottom: 0 !important;
}
/* .ant-menu-item:hover{
  bottom: 0!important;
} */
.ant-menu-overflow li {
  line-height: unset !important;
  font-size: unset !important;
}
.three-dot {
  overflow: hidden;
  /* width:100px; */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.not-found-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #1a1a1a;
}
.not-found-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  color: #515151;
}

.ant-modal-content {
  background-color: transparent !important;
  box-shadow: unset !important;
  justify-content: center;
  align-items: center;
  display: flex;
}
.coin {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  text-align: right;

  color: #ffffff;
}

.coin-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  text-align: right;

  color: #f8bb22;
}

.modal-login-vertical-center {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
.otp-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Black */

  color: #14191f;
}
/* .ant-modal-body{
  width: 100%;
} */

.offer-card {
  background: linear-gradient(
    94.32deg,
    #000000 0.43%,
    rgba(0, 0, 0, 0.7) 63.18%,
    #f8bb22 100%
  );
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.15), -1px -1px 4px #829bc1,
    6px 6px 18px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: 280px;
  width: 100%;
  position: relative;
}
.offer-card-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.24px;
  text-transform: capitalize;

  color: #ffffff;
}
.tnc-apply-txt {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: left;

  color: #ffffff;
  margin-top: 20px;
}

.invite-card {
  background: linear-gradient(94.32deg, #004dc2 0.43%, #820010 100%);
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.15), -1px -1px 4px #829bc1,
    6px 6px 18px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: 280px;
  width: 100%;
  position: relative;
}

@media (max-width: 768px) {
  .offer-card-title {
    font-size: 18px;
    line-height: 25px;
  }
  .offer-card {
    height: 220px;
  }
  .invite-card {
    height: 220px;
  }
  .tnc-apply-txt {
    margin-top: 10px;
  }
}

.prediction-container {
  background-color: #fdeaba;
  padding: 10px;
  border-radius: 15px;
 
}
.prediction-container1 {
  background-color: #fdeaba;
  padding-left:10px;
  padding-right: 10px;
  border-radius: 10px;
 
}
.prediction-row{
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.prediction-button {
  border-radius: 10px;
  background: #000;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.27), -1px -1px 4px 0px #cfcfcf;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: white;
  padding: 10px;
}

.success{
  color: #1F9138;
text-align: center;
font-size: 10px;

font-weight: 600;
line-height: 18px;
letter-spacing: 1px;
}
.draw{
  color: #737373;

font-size: 10px;

font-weight: 600;
}
.low{
  color: #FF4F4F;
text-align: center;
font-size: 10px;

font-weight: 600;
line-height: 18px;
letter-spacing: 1px;
}

.pol-date{
  color: #103C8C;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.5px;
}
.pol-result{

text-align: right;
font-size: 14px;
font-style: italic;
font-weight: 400;
line-height: normal;
}
.box-poll {
  filter: drop-shadow(4px 4px 7px rgba(0, 0, 0, 0.07))
    drop-shadow(-4px -4px 13px #ffffff)
    drop-shadow(6px 6px 36px rgba(0, 0, 0, 0.06));
  border-radius: 20px;
  height: 170px;
  width: 100%;
   background-color: #FFFFFF;
   position: relative;
   margin-bottom: 20px;
}
.head-ad{
  color: black;
  font-weight: bold;
  width: 100%;
  text-align: center;
  line-height: 16px;
}
