.box {
  filter: drop-shadow(4px 4px 7px rgba(0, 0, 0, 0.07))
    drop-shadow(-4px -4px 13px #ffffff)
    drop-shadow(6px 6px 36px rgba(0, 0, 0, 0.06));
  border-radius: 20px;
  height: 200px;
  width: 100%;
   background-color: #FFFFFF;
   position: relative;
   margin-bottom: 20px;
}
.heading-container {
  height: 50px;
  background: rgba(248, 187, 34, 0.1);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
}
.flex-row-heading {
  flex-direction: row;
  display: flex;
}
.fixture-match-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: #003687;
}
.fixture-devider {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2px;
}
.fixture-time{
    font-weight: 700;
font-size: 14px;
line-height: 18px;
letter-spacing: 0.5px;
color: #707585;
}

.fixture-date{
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */

letter-spacing: 0.5px;

color: #707585;

}
.fixture-venue{
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */

letter-spacing: 0.5px;

color: #707585;
}
.live-tag{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 2px 10px;
gap: 10px;

position: absolute;
width: 64px;
height: 22px;
right: 24px;
top: 14px;

background: #FF5858;
border-radius: 5px;
}
.live-tag-text{
    font-weight: 400;
font-size: 13px;
line-height: 18px;
/* identical to box height, or 138% */

text-align: center;
letter-spacing: 1px;

color: #FFFFFF;


}
.fixture-teamcode{
    font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */


color: #333333;
margin-left: 20px;

}
.pd-20{
    padding: 20px;
}
.fixture-score{
    font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

margin-right: 10px;
color: #333333;
}
.fixture-note{
    position: absolute;

height: 50px;
left: 0px;
bottom: 0px;
width: 100%;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
background: #F4F4F4;
align-items: center;
display: flex;
justify-content: space-between;
padding: 10px;
}
.note-text{
    font-weight: 400;
font-size: 14px;
line-height: 18px;
width: 40%;
/* identical to box height, or 129% */

letter-spacing: 0.5px;

color: #707585;
margin-left: 10px;
}